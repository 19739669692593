<template>
  <div class="top-line">
    <Delivery />

    <Navigation />

    <SignIn />
  </div>
</template>

<script>
import SignIn from '@/components/Header/HeaderModules/HeaderTopLine/SignIn'
import Delivery from '@/components/Header/HeaderModules/HeaderTopLine/Delivery'
import Navigation from '@/components/Header/HeaderModules/HeaderTopLine/Navigation'

export default {
  name: 'HeaderTopLine',

  components: {
    SignIn,
    Delivery,
    Navigation,
  },
}
</script>

<style lang="scss" scoped>
.top-line {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: rem(38);
  border-bottom: rem(1) solid #f5f5f5;
}

@media only screen and (max-width: $breakpoint-desktop) {
  .top-line {
    display: none;
  }
}
</style>
