<template>
  <div class="delivery">
    <CIcon name="placemark" class="delivery__mark" />
    <span class="delivery__region">Регион доставки:</span>
    <button type="button" class="delivery__city" @click="openDeliveryModal">
      Москва и МО
      <CIcon name="chevron-down" class="delivery__icon" />
    </button>

    <portal to="layout">
      <component :is="modal" @close="modal = null" />
    </portal>
  </div>
</template>

<script>
import CIcon from '@/components/common/CIcon'
import { createDeliveryModal } from '@/components/modals/index'

export default {
  name: 'Delivery',

  components: {
    CIcon,
  },

  data: () => ({
    modal: null,
  }),

  methods: {
    async openDeliveryModal() {
      this.modal = await createDeliveryModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.delivery {
  display: flex;
  align-items: center;
  &__mark {
    margin: 0 rem(7) 0 0;
    fill: $color-green;
    @include base-width(14);
  }
  &__region {
    font-size: rem(13.5);
  }
  &__city {
    display: inline-flex;
    align-items: center;
    margin: 0 0 0 rem(13);
    color: $color-green;
    font-weight: 900;
    font-size: rem(13.5);
    letter-spacing: rem(-0.5);
  }
  &__icon {
    margin: rem(2) 0 0 rem(4);
    fill: $color-green;
    @include base-width(7);
  }
}
</style>
