<template>
  <header class="header">
    <div class="container">
      <HeaderTopLine />
      <HeaderMiddleLine />
    </div>
    <HeaderBottomLine />
  </header>
</template>

<script>
import HeaderTopLine from '@/components/Header/HeaderModules/HeaderTopLine/index'
import HeaderMiddleLine from '@/components/Header/HeaderModules/HeaderMiddleLine'
import HeaderBottomLine from '@/components/Header/HeaderModules/HeaderBottomLine/index'

export default {
  name: 'Header',

  components: {
    HeaderTopLine,
    HeaderMiddleLine,
    HeaderBottomLine,
  },
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: $breakpoint-desktop) {
  .header {
    min-height: rem(90);
  }
}
</style>
