<template>
  <nav class="nav">
    <a
      v-for="(item, i) in [
        'Оплата',
        'Доставка',
        'Статус заказа',
        'Отзывы',
        'О нас',
        'Контакты',
      ]"
      :key="i"
      class="nav__link"
      href="#"
      >{{ item }}</a
    >
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
}
</script>

<style lang="scss" scoped>
.nav {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin: 0 rem(-11);
  &__link {
    display: inline-block;
    margin: 0 rem(11);
    color: $text-color;
    font-size: rem(13.5);
    text-decoration: none;
    transition: color $default-transition;
    &:hover {
      color: $color-green;
    }
  }
}
</style>
