<template>
  <!-- TODO: разнести по компонентам -->
  <div id="middleLine" class="middle-line" :style="hidePartStyle">
    <HeaderHamburger />
    <button aria-label="call" class="call-btn" type="button">
      <CIcon class="call-btn__icon" name="call" />
    </button>

    <div class="logo">
      <nuxt-link to="/">
        <img class="logo__img" :src="logo" alt="flor2u" />
      </nuxt-link>
      <p class="logo__sinopsis">Дарите. Радуйте. Удивляйте!</p>
    </div>

    <div id="contactsBlock" class="contacts">
      <a class="contacts__mobile" href="tel:88006004360">+7 (800) 600-43-60</a>
      <p class="contacts__worktime">Работаем ежедневно и круглосуточно 24/7</p>
      <a class="contacts__phone" href="tel:84951206586">8 (495) 120-65-86</a>
    </div>

    <div class="actions">
      <button type="button" class="actions__add-favorites" @click="toggleHeart">
        <CIcon
          :name="isFilledHeart ? 'fill-heart' : 'heart'"
          class="actions__heart"
        />
        Избранное
      </button>
      <span class="actions__sep"></span>
      <button type="button" class="actions__add-cart">
        <CIcon name="cart" class="actions__cart" />
        Корзина
        <span class="actions__price">0 руб.</span>
      </button>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/svg/logo.svg'
import CIcon from '@/components/common/CIcon'
import HeaderHamburger from '@/components/Header/HeaderModules/HeaderHamburger'

export default {
  name: 'HeaderMiddleLine',

  components: {
    HeaderHamburger,
    CIcon,
  },

  data() {
    return {
      isFixed: false,
      isFilledHeart: false,

      headerPartOffset: 0,
    }
  },

  computed: {
    hidePartStyle() {
      if (!this.$screen?.desktopAndDown) return {}
      return {
        transform: `translate3d(0, -${this.headerPartOffset / 16}rem,0)`,
      }
    },
  },

  created() {
    this.logo = logo
  },

  mounted() {
    window.addEventListener('scroll', this.togglePartHeader)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.togglePartHeader)
  },

  methods: {
    toggleHeart() {
      this.isFilledHeart = !this.isFilledHeart
    },

    togglePartHeader(e) {
      const baseHeight = 36
      if (window.pageYOffset > baseHeight) {
        this.headerPartOffset = baseHeight
      } else {
        this.headerPartOffset = window.pageYOffset
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.middle-line {
  display: flex;
  margin: 0 0 rem(18) 0;
  padding: rem(12) 0 0 0;
}

.logo {
  width: 100%;
  max-width: rem(300);
  &__img {
    width: 100%;
    max-width: rem(160);
    margin: 0 0 rem(6) 0;
  }
  &__sinopsis {
    font-size: rem(13.5);
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  &__mobile {
    margin-bottom: rem(6);
    color: $text-color;
    font-size: rem(14);
    text-decoration: none;
    transition: color $default-transition;
    &:hover {
      color: $color-green;
    }
  }
  &__worktime {
    margin-bottom: rem(6);
    font-size: rem(14);
  }
  &__phone {
    color: $text-color;
    font-weight: 300;
    font-size: rem(22);
    text-decoration: none;
    transition: color $default-transition;
    &:hover {
      color: $color-green;
    }
  }
}

.actions {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  max-width: rem(300);
  &__heart {
    margin: 0 rem(8) 0 0;
    fill: $color-pink;
    @include base-width(22);
  }
  &__add-favorites {
    display: inline-flex;
    align-items: center;
    font-size: rem(13.5);
    transition: color $default-transition;
    &:hover {
      color: $color-green;
    }
  }
  &__sep {
    display: inline-block;
    width: rem(1);
    height: rem(23);
    margin: 0 rem(12);
    background-color: $color-green;
  }
  &__add-cart {
    display: inline-flex;
    align-items: center;
    font-size: rem(13.5);
    transition: color $default-transition;
    &:hover {
      color: $color-green;
    }
  }
  &__cart {
    margin: 0 rem(8) 0 0;
    @include base-width(22);
  }
  &__price {
    display: inline-flex;
    margin-left: rem(12);
    padding: rem(4) rem(9);
    color: #fff;
    font-weight: 600;
    font-size: rem(11);
    text-transform: uppercase;
    background-color: $color-pink;
    border-radius: rem(3);
  }
}

.call-btn {
  display: none;
  order: 3;
  margin-left: rem(12);
  &__icon {
    fill: $color-green;
    @include base-width(26);
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  .call-btn {
    display: inline-flex;
  }

  .middle-line {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: rem(12) rem(9);
    background-color: #fff;
    will-change: transform;
  }

  .contacts {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    order: 1;
    width: 100%;
    padding: 0 0 rem(24) 0;
    &__worktime {
      order: 1;
      margin: 0 rem(12) 0 0;
    }
    &__mobile,
    &__phone {
      order: 2;
      margin: 0 rem(6) 0 0;
      color: $color-green;
      font-weight: 400;
      font-size: rem(14);
    }
    &__mobile {
      &:after {
        color: $text-color;
        content: ',';
      }
    }
  }

  .logo {
    position: absolute;
    top: rem(48);
    right: 0;
    left: 0;
    width: auto;
    max-width: unset;
    max-width: rem(144);
    margin: 0 auto;
    &__sinopsis {
      display: none;
    }
  }

  .actions {
    order: 4;
    width: auto;
    max-width: unset;
    margin-left: auto;
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .contacts {
    &__worktime {
      display: none;
    }
  }

  .actions {
    &__add-favorites,
    &__add-cart {
      font-size: 0;
    }
    &__heart,
    &__cart {
      margin: 0;
    }
  }
}

@media only screen and (max-width: $breakpoint-large-mobile) {
  .actions {
    &__price {
      display: none;
    }
  }
}
</style>
