<template>
  <div class="signin" :class="{ invert }">
    <button type="button" class="signin__btn">
      <CIcon name="open-lock" class="signin__lock" />
      Войти
    </button>
    <button type="button" class="signin__socials">
      <CIcon name="viber" class="signin__viber" />
      <CIcon name="whatsapp" class="signin__whatsapp" />
      <CIcon name="telegram" class="signin__telegram" />
    </button>
    <button type="button" class="signin__call" @click="openCallbackModal">
      Заказать звонок
    </button>

    <portal to="layout">
      <component :is="modal" @close="modal = null" />
    </portal>
  </div>
</template>

<script>
import CIcon from '@/components/common/CIcon'
import { createCallbackModal } from '@/components/modals/index'

export default {
  name: 'SignIn',

  components: {
    CIcon,
  },

  props: {
    invert: Boolean,
  },

  data() {
    return {
      modal: null,
    }
  },

  methods: {
    async openCallbackModal() {
      this.modal = await createCallbackModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.signin {
  display: inline-flex;
  align-items: center;
  &__lock {
    margin: 0 rem(10) 0 0;
    fill: $color-green;
    @include base-width(18);
  }
  &__viber,
  &__whatsapp,
  &__telegram {
    @include base-width(16);
  }
  &__whatsapp,
  &__telegram {
    margin-left: rem(-4);
  }
  &__btn {
    display: inline-flex;
    align-items: center;
    margin: 0 rem(8) 0 0;
    font-size: rem(13.5);
    transition: color $default-transition;
    &:hover {
      color: $color-green;
    }
  }
  &__socials {
    display: inline-flex;
    margin: 0 rem(5) 0 0;
  }
  &__call {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: rem(18);
    padding: 0 rem(11);
    color: $color-green;
    font-weight: 700;
    font-size: rem(9);
    text-transform: uppercase;
    border: rem(1) solid $color-green;
    border-radius: rem(3);
    transition: color $default-transition;
    &:hover {
      color: $text-color;
    }
  }

  &.invert {
    .signin__lock {
      fill: #fff;
    }
    .signin__btn {
      color: #fff;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  .signin {
    margin: auto rem(-9) 0 rem(-9);
    padding: 0 rem(13);
    &__btn {
      width: 100%;
      min-height: rem(45);
      margin: 0;
    }
    &__socials,
    &__call {
      display: none;
    }
  }
}
</style>
