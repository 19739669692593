const MENU_CODES = {
  flowers: 'flowers',
  reason: 'reason',
  roses: 'roses',
  gifts: 'gifts',
}

export const MENU = [
  {
    name: 'Цветы',
    icon: 'flower',
    code: MENU_CODES.flowers,
  },
  {
    name: 'Повод',
    icon: 'calendar',
    code: MENU_CODES.reason,
  },
  {
    name: 'Розы',
    icon: 'rose',
    code: MENU_CODES.roses,
  },
  {
    name: 'Подарки',
    icon: 'gift',
    code: MENU_CODES.gifts,
  },
]

export const SUB_MENU = {
  [MENU_CODES.flowers]: [
    {
      title: 'Букеты с:',
      buttonName: 'Все виды цветов',
      links: [
        {
          name: 'Все цветы',
          href: '/',
        },
        {
          name: 'Розами',
          href: '/',
        },
        {
          name: 'Амариллисами',
          href: '/',
        },
        {
          name: 'Альстромериями',
          href: '/',
        },
        {
          name: 'Гипсофилой',
          href: '/',
        },
        {
          name: 'Герберами',
          href: '/',
        },
        {
          name: 'Гвоздиками',
          href: '/',
        },
        {
          name: 'Гортензиями',
          href: '/',
        },
        {
          name: 'Дельфиниумами',
          href: '/',
        },
        {
          name: 'Ирисами',
          href: '/',
        },
        {
          name: 'Каллами',
          href: '/',
        },
        {
          name: 'Кустовыми розами',
          href: '/',
        },
        {
          name: 'Лилиями',
          href: '/',
        },
        {
          name: 'Маттиолами',
          href: '/',
        },
        {
          name: 'Орхидеями',
          href: '/',
        },
        {
          name: 'Подсолнухами',
          href: '/',
        },
        {
          name: 'Пионовидными розами',
          href: '/',
        },
        {
          name: 'Ромашками',
          href: '/',
        },
        {
          name: 'Суккулентами',
          href: '/',
        },
        {
          name: 'Сухоцветами',
          href: '/',
        },
        {
          name: 'Статицей',
          href: '/',
        },
        {
          name: 'Тюльпанами',
          href: '/',
        },
        {
          name: 'Фрезиями',
          href: '/',
        },
        {
          name: 'Хризантемами',
          href: '/',
        },
        {
          name: 'Эустомами',
          href: '/',
        },
      ],
    },
  ],
}
